import BannerComBotoes from "./Modulos/BannerComBotoes/bannerComBotoes";
import { BannerFloating } from "./Modulos/BannerFloating/bannerFloating";
import { BannerFloatingFlexSlider } from "./Modulos/BannerFloating/bannerFloatingFlexSlider";
import { BolsasDeEstudoDescontos } from "./Modulos/BolsaDeEstudoDescontos/bolsasDeEstudoDescontos";
import { BolsasDeEstudoDescontosAtualizado } from "./Modulos/BolsaDeEstudoDescontosAtualizado/bolsasDeEstudoDescontosAtualizado";
import { BuscaPorPolos } from "./Modulos/BuscaPorPolos/buscaPorPolos";
import CalculadoraEnem from "./Modulos/CalculadoraEnem/calculadoraEnem";
import { ContadorDeInteressados } from "./Modulos/ContadorDeInteressados/contadorDeInteressados";
import ConteSuaHistoria from "./Modulos/ConteSuaHistoria/conteSuaHistoria";
import { DesativarContinuarLendo, StartSliderConteSuaHistoriaSimples } from "./Modulos/ConteSuaHistoriaSimples/conteSuaHistoriaSimples";
import ConteudoPesquisaNovo from "./Modulos/ConteudoPesquisaNovo/conteudoPesquisaNovo";
import BannerHome from "./Modulos/CxBannerHome/bannerHome";
import { SliderComite } from "./Modulos/CxCarrosselSimples/SliderComite";
import { CxConteudoSliderDiretivo } from "./Modulos/CxConteudoSliderDiretivo/CxConteudoSliderDiretivo";
import { CxDestaqueCarrossel } from "./Modulos/CxDestaqueCarrossel/CxDestaqueCarrossel";
import { CxDestaquePage } from "./Modulos/CxDestaquePage/CxDestaquePage";
import { CxIndicadoresIes } from "./Modulos/CxIndicadoresIes/CxIndicadoresIes";
import { InscricoesProcesso } from "./Modulos/CxInscricoesProcesso/InscricoesProcesso";
import { CxIntervencao } from "./Modulos/CxIntervencao/cxIntervencao";
import { CxModalBotoes, CxModalBotoesOnLoad } from "./Modulos/CxModalBotoes/CxModalBotoes";
import { CxSliderGaleria } from "./Modulos/CxSliderGaleria/cxSliderGaleria";
import { TextoAbaLateral } from "./Modulos/CxTextoAbaLateral/TextoAbaLateral";
import { CxWhatsAppFloat } from "./Modulos/CxWhatsAppFloat/cxWhatsAppFloat";
import { DuvidasPdp } from "./Modulos/DuvidasPdp/duvidasPdp";
import { EncontreOCurso } from "./Modulos/EncontreOCurso/encontreOCurso";
import { Enem } from "./Modulos/Enem/enem";
import { EspacoDoCandidato } from "./Modulos/EspacoDoCandidato/espacoDoCandidato";
import { Footer } from "./Modulos/Footer/footer";
import FormasIngresso from "./Modulos/FormasIngresso/formasIngresso";
import { campoBusca } from "./Modulos/Header/campoBusca";
import { Header } from "./Modulos/Header/header";
import { ImagemOtimizada } from "./Modulos/ImagemOtimizada/imagemOtimizada";
import { ListaDeBeneficios } from "./Modulos/ListaDeBeneficios/listaDeBeneficios";
import ModalApoio from "./Modulos/ModalApoio/modalApoio";
import { ModalCalculadoraEnemNovo } from "./Modulos/ModalCalculadoraEnemNovo/ModalCalculadoraEnemNovo";
import { Modalidades } from "./Modulos/Modalidades/modalidades";
import ModalProvaTradicional from "./Modulos/ModalProvaTradicional/modalProvaTradicional";
import ModuloFormulario from "./Modulos/ModuloFormulario/moduloFormulario";
import { MosaicoInformacoes } from "./Modulos/MosaicoInformacoes/mosaicoInformacoes";
import { NoticiasEventos } from "./Modulos/NoticiasEventos/noticiasEventos";
import { Pdp, PdpOnLoad } from "./Modulos/Pdp/pdps";
import { AbrirArquivoCampus, IniciarSliderCampus } from "./Modulos/Pdp/Sessoes/Campus/pdpSessaoCampus";
import AbrirVideoCoordenador from "./Modulos/Pdp/Sessoes/Coordenador/pdpSessaoCoordenador";
import LerMaisDiferenciais from "./Modulos/Pdp/Sessoes/Diferenciais/pdpSessaoDiferenciais";
import LerMaisMercadoTrabalho from "./Modulos/Pdp/Sessoes/MercadoTrabalho/pdpSessaoMercadoTrabalho";
import LerMaisObservacoesAdicionais from "./Modulos/Pdp/Sessoes/ObservacoesAdicionais/pdpSessaoObservacoesAdicionais";
import LerMaisSobreCurso from "./Modulos/Pdp/Sessoes/Sobre/pdpSessaoSobre";
import { abrirFecharModalArquivos, mostrarArquivos, verificarInput } from "./Modulos/Pdp/Sessoes/Sobre/pdpSessaoSobreArquivos";
import { PdpSidebarAcoesFormulario } from "./Modulos/Pdp/Sidebar/pdpSidebarAcoesFormulario";
import { PdpSidebarDadosFormaIngresso } from "./Modulos/Pdp/Sidebar/pdpSidebarDadosFormaIngresso";
import { BuscarCurso, MudarTipoCurso } from "./Modulos/PesquisaCurso/pesquisaCurso";
import SliderBoxes from "./Modulos/SliderBoxes/sliderBoxes";
import TesteVocacional from "./Modulos/TesteVocacional/testeVocacional";
import { vitrineOnLoad } from "./Modulos/Vitrine/vitrine";
import { AlterarFonte, CarregarAlteracaoFonte } from "./Utils/alteraFonte";
import { DocumentReady } from "./Utils/DocumentReady";
import EmbedContentSize from "./Utils/embed/embedContentSize";
import { ModoContraste } from "./Utils/modoContraste";
import { ScriptLegado } from "./Utils/scriptLegado";
import { SelectModal } from "./Utils/selectModal";
import Tooltip from "./Utils/tooltip";

window.addEventListener('load', () => {
  CarregarAlteracaoFonte();
  DocumentReady();

  if (document.querySelector('.modoContraste')) {
    ModoContraste();
  }

  if (document.querySelector('.footer__modulo')) {
    Footer();
  }

  if (document.querySelector('.header')) {
    Header();
  }

  if (document.querySelector('.imagem-otimizada')) {
    ImagemOtimizada();
  }

  if (document.querySelector('#cComite')) {
    SliderComite();
  }

  if (document.querySelector('.alterarFonte')) {
    AlterarFonte();
  }

  if (document.querySelector('.modal-de-botoes')) {
    CxModalBotoesOnLoad();
  }

  if (document.querySelector('.vitrine-section')) {
    vitrineOnLoad();
  }

  if (document.querySelector('.js-btn-investimento, .js-btn-duracaocurso')) {
    PdpOnLoad();
  }

  if (document.querySelector('#historia')) {
    CxDestaquePage();
  }

  if (document.querySelector('#instituicoes')) {
    CxIndicadoresIes();
  }
});

ScriptLegado();
SelectModal();

if (document.querySelector('.formas-ingresso')) {
  FormasIngresso();
}

if (document.querySelector('.pdp-sessao__sobre .quantidade-conteudo')) {
  LerMaisSobreCurso();
}

if (document.querySelector('.modal__video-coordenador')) {
  AbrirVideoCoordenador();
}

if (document.querySelector('.pdp-sessao__mercado-trabalho')) {
  LerMaisMercadoTrabalho();
}

if (document.querySelector('.pdp-sessao__observacoes-adicionais')) {
  LerMaisObservacoesAdicionais();
}

if (document.querySelector('.pdp-sessao__diferenciais')) {
  LerMaisDiferenciais();
}

if (document.querySelector('.pdp-sessao__sobre .documentos-botao')) {
  abrirFecharModalArquivos();
  verificarInput();
  mostrarArquivos();
}

if (document.querySelector('.pdp-sessao__campus')) {
  AbrirArquivoCampus();
}

if (document.querySelector('.slider-boxes')) {
  SliderBoxes();
}

if (document.querySelector('.banner-buttons__slide')) {
  BannerComBotoes();
}

if (document.querySelector('.pesquisa-curso')) {
  BuscarCurso();
  MudarTipoCurso();
}

if (document.querySelector('.conte_sua_historia_simples__modulo')) {
  DesativarContinuarLendo();
}

if (document.querySelector('.modal-prova-antecipada')) {
  ModalProvaTradicional();
}

if (document.querySelector('.tooltip')) {
  Tooltip();
}

if (document.querySelector('.modulo-formulario')) {
  ModuloFormulario();
}

if (document.querySelector('.calculadora-enem')) {
  CalculadoraEnem();
}

if (document.querySelector('#btnIntervencao-open')) {
  CxIntervencao();
}

if (document.querySelector('.btnsfixos-ajuda')) {
  CxWhatsAppFloat();
}

if (document.querySelector('.modalidades')) {
  Modalidades();
}

if (document.querySelector('.modulo-mosaico-informacoes')) {
  MosaicoInformacoes();
}

if (document.querySelector('.sessao-espaco-do-candidato')) {
  EspacoDoCandidato();
}

if (document.querySelector('.modulo-enem')) {
  Enem();
}

if (document.querySelector('.banner.floating')) {
  BannerFloating();
}

if (document.querySelector('#PrincipaisDuvidas.duvidasPdp')) {
  DuvidasPdp();
}

if (document.querySelector('.textoAbaLateral')) {
  TextoAbaLateral();
}

if (document.querySelector('.contador-de-interessados')) {
  ContadorDeInteressados();
}

if (document.querySelector('.busca-por-polos')) {
  BuscaPorPolos();
}

if (document.querySelector('.conte_sua_historia_simples__modulo')) {
  StartSliderConteSuaHistoriaSimples();
}

if (document.querySelector('.slider-galeria__slides')) {
  CxSliderGaleria();
}

if (document.querySelector('.lista-de-beneficios')) {
  ListaDeBeneficios();
}

if (document.querySelector('.modal-apoio')) {
  ModalApoio();
}

if (document.querySelector('.noticias_eventos__modulo')) {
  NoticiasEventos();
}

if (document.querySelector('.pdp-novos-carrinhos')) {
  Pdp();
}

if (document.querySelector('.pdp-sessao__campus')) {
  IniciarSliderCampus();
}

if (document.querySelector('.sidebar-pdp-tela-um-acoes')) {
  PdpSidebarAcoesFormulario();
}

if (
  document.querySelector('.sidebar-pdp-tela__proximo[data-proxima-tela-inscreva-se="quase-la"]')
) {
  PdpSidebarDadosFormaIngresso();
}

if (document.querySelector('.modal-calculadora-enem')) {
  ModalCalculadoraEnemNovo();
}

if (document.querySelector('.menuop')) {
  ConteudoPesquisaNovo();
}

if (document.querySelector('.modulo-teste-vocacional')) {
  TesteVocacional();
}

if (document.querySelector('#gradeEnsinoCompleta .cardsGradeEnsinoCompleta')) {
  InscricoesProcesso();
}

if (document.querySelector('.modal-de-botoes')) {
  CxModalBotoes();
}

if (document.querySelector('#agencias')) {
  CxDestaqueCarrossel();
}

if (document.querySelector('#corpoDiret')) {
  CxConteudoSliderDiretivo();
}

if (document.querySelector('#banner')) {
  BannerFloatingFlexSlider();
}

if (document.querySelector('.embed-main')) {
  EmbedContentSize(<HTMLElement>document.querySelector('.embed-main'));
}

if (document.querySelector('.bolsas-estudo-descontos__list')) {
  BolsasDeEstudoDescontos();
}

if (document.querySelector('.bolsas-estudo-descontos-atualizado__lista')) {
  BolsasDeEstudoDescontosAtualizado();
}

if (document.querySelector('input[name="s"]')) {
  campoBusca();
}

if (document.querySelector('.sessao-encontre-o-curso')) {
  const encontreOCurso: HTMLElement | null = document.querySelector('.sessao-encontre-o-curso');

  if (encontreOCurso) {
    EncontreOCurso(encontreOCurso);
  }
}

if (document.querySelector('.conte-sua-historia')) {
  const modulo = <HTMLElement>document.querySelector('.conte-sua-historia');
  const modal = <HTMLElement>document.querySelector('.conte-sua-historia-modal');

  ConteSuaHistoria(modulo, modal);
}

if (document.querySelector('#bannerHome')) {
  BannerHome();
}