export const TesteVocacionalTelasFormulario = (modulo: HTMLElement) => {
  const iniciaFormulario = <HTMLButtonElement>modulo.querySelector('.inicia-formulario');

  iniciaFormulario.addEventListener('click', () => {
    iniciaFormulario.parentElement?.classList.add('iniciado');
  })
}

export const TesteVocacionalTelasPerguntas = (modulo: HTMLElement) => {
  const capa = <HTMLElement>modulo.querySelector('.capa');
  const perguntas = <HTMLElement>modulo.querySelector('.perguntas');
  const segundoFormulario = <HTMLElement>modulo.querySelector('.segundo-formulario');

  modulo.classList.remove('capa-on');
  modulo.classList.remove('segundo-formulario-on');

  capa.classList.remove('on');
  segundoFormulario.classList.remove('on');

  modulo.classList.add('perguntas-on');
  perguntas.classList.add('on');
}

export const TesteVocacionalTelasSegundoFormulario = (modulo: HTMLElement) => {
  const capa = <HTMLElement>modulo.querySelector('.capa');
  const perguntas = <HTMLElement>modulo.querySelector('.perguntas');
  const segundoFormulario = <HTMLElement>modulo.querySelector('.segundo-formulario');

  modulo.classList.remove('capa-on');
  capa.classList.remove('on');
  perguntas.classList.remove('on');

  modulo.classList.add('segundo-formulario-on');
  segundoFormulario.classList.add('on');
}