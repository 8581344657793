export const SelectModal = () => {
  let a = setInterval(function () {
    if (typeof window.jQuery === 'undefined' && typeof window.$ === 'undefined') {
      return;
    }
    clearInterval(a);

    function verificarSeUrlEValida(url: string) {
      try {
        new URL(url);
        return true;
      } catch (err) {
        return false;
      }
    }

    $("a").click(function (e) {
      e.preventDefault();
      let href = $(this).attr("href");
      let dominioPagina: string = window.location.hostname.split('www.').at(-1) ?? '';
      if (href) {
        if (href.toLowerCase().includes(dominioPagina)) {
          if(verificarSeUrlEValida(href)) {
            let target = this.getAttribute('target') ?? '_self';
            window.open(href, target);
          }
        } else if (href.match(/cruzeirodosulvirtual.com.br/) && !window.location.hostname.match(/cruzeirodosulvirtual.com.br/) && !href.match(/enemantecipado/) && !url.match(/politica-de-privacidade/) && !$(this).hasClass('cta-formas-de-ingresso-ead')) {
          var utm = $('.botao-redirect-ead').attr('data-utm');
          $(".botao-redirect-ead").attr("href", href + utm);
          $(".modal-redirecionamento").removeClass('disable-modal');
          $(".bg_lightbox").removeClass('disable-modal');
          $("body").addClass('blockBody');
          return false;
        } else if (href.match(/www.orientaportal.com.br/) && href.match(/quiz/)) {
          $(".modal-orienta").removeClass('disable-modal');
          $(".bg_lightbox").removeClass('disable-modal');
          $("body").addClass('blockBody');
          return false;
        } else if (href.match(/avisememedicina/)) {
          $(".modal-avise").removeClass('disable-modal');
          $(".bg_lightbox").removeClass('disable-modal');
          $("body").addClass('blockBody');
          $("#identificadorAviseMe").val("avisememedicina");
          $("#modal-estude").remove();
        } else if (href.match(/#aviseMeTradicional/)) {
          $(".modal-avise").removeClass('disable-modal');
          $(".bg_lightbox").removeClass('disable-modal');
          $("body").addClass('blockBody');
          $("#identificadorAviseMe").val("Avise-me transferência");
          $("#modal-estude").remove();
        } else if (href.match(/aviseMeEnem/)) {
          $(".modal-avise").removeClass('disable-modal');
          $(".bg_lightbox").removeClass('disable-modal');
          $("body").addClass('blockBody');
          $("#identificadorAviseMe").val("aviseMeEnem");
          $("#modal-estude").remove();
        } else if (href.match(/aviseMeAgendado/)) {
          $(".modal-avise").removeClass('disable-modal');
          $(".bg_lightbox").removeClass('disable-modal');
          $("body").addClass('blockBody');
          $("#identificadorAviseMe").val("aviseMeAgendado");
          $("#modal-estude").remove();
        } else if (href.match(/www.orientaportal.com.br/) && href.match(/conheca/)) {
          $(".modal-visite").removeClass('disable-modal');
          $(".bg_lightbox").removeClass('disable-modal');
          $("body").addClass('blockBody');
          return false;
        } else {
            let target = this.getAttribute('target') ?? '_self';
            window.open(href, target);
        }
      }
    });

    var acc = document.getElementsByClassName("accordion");
    var i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }
  }, 500);
};