import { ModalAviseMe } from "../FormasIngresso/formasIngressoTelas";
import { PdpDataLayer } from "./pdpDataLayer";
import { PdpInformacoesRapidasCursosGraduacaoBase } from "./pdpInformacoesRapidasCursosGraduacaoBase";
import { PdpInformacoesRapidasCursosLivresBase } from "./pdpInformacoesRapidasCursosLivresBase";
import { PdpInformacoesRapidasCursosPosGraduacaoBase } from "./pdpInformacoesRapidasCursosPosGraduacaoBase";
import { TooltipTelaInvestimentoGraduacao } from "./Sidebar/pdpSidebarTooltipTelaInvestimentoGraduacao";
import { TooltipTelaUm } from "./Sidebar/pdpSidebarTooltipTelaUm";

const sideBarPdpTelaConteudo = <NodeListOf<HTMLElement>>document.querySelectorAll('.sidebar-pdp-tela__conteudo');

const DefinirMarginTopToolTipPlanoPagamentoPdp = (elemeto: HTMLElement) => {
  let valorScroll = screen.width <= 992 ? document.body.scrollTop : window.scrollY;

  if (valorScroll <= 992) {
    let scroll: number = elemeto.scrollTop;

    document.documentElement.style.setProperty(
      '--margin-top-tooltip-plano-pagamento-pdp',
      scroll <= 25 ? '25px' : `-${(scroll - 25)}px`
    );
  }
};

const IncluirNavegacao = () => {
  let elemento = <HTMLAnchorElement>document.querySelector('a.js-btn-duracaocurso');
  elemento?.setAttribute('href', "#Informacoes-rapidas");
};

const BloquearScroll = (evento) => {
  evento.preventDefault();
  document.querySelector('body')?.classList.add('blockBody');
}

const ModalVideoCoordenador = () => {
  const modalVideoCoord = <HTMLElement>document.getElementById("ModalVideoCoord");
  const btnVideoCoord = <HTMLElement>document.getElementById("scconteudo-tp-coord-blocoinfo-video-btn");
  const btnVideoCoordMobile = <HTMLElement>document.getElementById("scconteudo-tp-coord-blocoinfo-video-btn-2");
  const spanVideoCoord = <HTMLElement>document.querySelector(".scconteudo-tp-coord-blocoinfo-video-mconteudo-fechar");
  const bodystop = <HTMLElement>document.querySelector(".single");

  if (btnVideoCoord) {
    btnVideoCoord.addEventListener('click', (evento) => {
      BloquearScroll(evento);

      modalVideoCoord.style.display = "block";
      bodystop.style.position = "fixed";
      bodystop.style.height = "100vh";
    });
  }

  if (btnVideoCoordMobile) {
    btnVideoCoordMobile.addEventListener('click', (evento) => {
      BloquearScroll(evento);

      modalVideoCoord.style.display = "block";
      bodystop.style.position = "fixed";
      bodystop.style.height = "100vh";
    });
  }

  if (spanVideoCoord) {
    spanVideoCoord.addEventListener('click', (evento) => {
      BloquearScroll(evento);

      modalVideoCoord.style.display = "none";
      bodystop.style.position = "initial";
      bodystop.style.height = "initial";
    });
  }

  window.addEventListener('click', (evento) => {
    if (evento.target == modalVideoCoord) {
      modalVideoCoord.style.display = "none";
      bodystop.style.position = "initial";
      bodystop.style.height = "initial";
    }
  });

  document.querySelector('#scconteudo-tp-coord-blocoinfo-video-btnmobile')?.addEventListener('click', (evento) => {
    BloquearScroll(evento);
  });
}

const BotaoDuracaoCursoOnLoad = () => {
  if (window.innerWidth <= 992) {
    $('.js-btn-duracaocurso').on('click', function (e) {
      $("html, body").animate({ scrollTop: 0 }, "fast");
      setTimeout(function () {
        $('.scs-cardinfo-resumo-duracao').closest('a').addClass('js-btn-duracaocurso-ativo');
      }, 1000);
      setTimeout(function () {
        $('.scs-cardinfo-resumo-duracao').closest('a').removeClass('js-btn-duracaocurso-ativo');
      }, 2000);
    });
  }

  if (window.innerWidth >= 992) {
    $('.js-btn-duracaocurso').on('click', function (e) {
      $('.scs-cardinfo-resumo-duracao').closest('a').addClass('js-btn-duracaocurso-ativo');
      setTimeout(function () {
        $('.scs-cardinfo-resumo-duracao').closest('a').removeClass('js-btn-duracaocurso-ativo');
      }, 2000);
    });
  }
}

const BotaoInvestimentoOnLoad = () => {
  if (window.innerWidth <= 992) {
    $('.js-btn-investimento').on('click', function (e) {
      $("html, body").animate({ scrollTop: 0 }, "fast");
      setTimeout(function () {
        $('.btnconhecamobile').addClass('js-btn-investimento-ativo');
      }, 1000);
      setTimeout(function () {
        $('.btnconhecamobile').removeClass('js-btn-investimento-ativo');
      }, 2000);
    });
  }

  if (window.innerWidth >= 992) {
    $('.js-btn-investimento').on('click', function (e) {
      $('.btnconheca').addClass('js-btn-investimento-ativo');
      setTimeout(function () {
        $('.btnconheca').removeClass('js-btn-investimento-ativo');
      }, 2000);
    });
  }
}

const BotaoDuracaoCursoEInvestimentoChamandoAtencao = () => {
  document.querySelectorAll('a.js-btn-investimento, a.js-btn-duracaocurso').forEach(botao => {
    botao.addEventListener('click', () => {
      let sidebar = (window.innerWidth > 992) ? '.sobre-curso-sidebar' : '.card-mobile';

      $('html').animate({
        scrollTop: (document.querySelector(`${sidebar}`).offsetTop + (sidebar === '.sobre-curso-sidebar' ? 200 : 0))
      }, "fast");

      setTimeout(() => {
        document.querySelector(`${sidebar}`).classList.toggle('chamando-atencao');
      }, 1000);

      setTimeout(() => {
        document.querySelector(`${sidebar}`).classList.toggle('chamando-atencao');
      }, 3000);
    });
  });
}

export const PdpOnLoad = () => {
  BotaoDuracaoCursoOnLoad();
  BotaoInvestimentoOnLoad();
}

export const Pdp = () => {
  sideBarPdpTelaConteudo.forEach((elemento: HTMLElement) => elemento
    .addEventListener('scroll', () => {
      DefinirMarginTopToolTipPlanoPagamentoPdp(elemento);
    })
  );

  TooltipTelaUm();
  ModalAviseMe();
  TooltipTelaInvestimentoGraduacao();

  if (document.querySelector('.pdp-novos-carrinhos-tipo--livre-base')) {
    PdpInformacoesRapidasCursosLivresBase();
  }

  if (document.querySelector('.informacoes-rapidas-coluna-tres-item-conteudos-slider')) {
    PdpInformacoesRapidasCursosPosGraduacaoBase();
  }

  if (document.querySelector('.informacoes-rapidas-coluna-dois-item-conteudos-slider')) {
    PdpInformacoesRapidasCursosGraduacaoBase();
  }

  if (document.querySelector('a.js-btn-duracaocurso')) {
    IncluirNavegacao();
  }

  if (document.querySelector('#ModalVideoCoord')) {
    ModalVideoCoordenador();
  }

  if (document.querySelector('form.form-valor')) {
    document.querySelectorAll('form.form-valor').forEach((elemento) => {
      elemento.addEventListener('submit', () => {
        const botaoValorCurso = <HTMLElement>document.querySelector('.presencial-btnvalordocurso');

        botaoValorCurso?.setAttribute('disabled', 'true');
        botaoValorCurso.textContent = 'Aguarde...';
        botaoValorCurso?.classList.add('form-btnloading');
      });
    });
  }

  if (document.querySelector('a.js-btn-investimento, a.js-btn-duracaocurso')) {
    BotaoDuracaoCursoEInvestimentoChamandoAtencao();
  }

  PdpDataLayer();
};